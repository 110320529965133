import React from "react";
import SALogo from "../../assets/imgs/SALogo.jpg";
import "./Footer.css";

function Footer() {
  return (
    <div class="footer">
      <div class="footer-left col-md-4 col-sm-6">
        <p class="about" style={{ textAlign: "justify" }}>
          <span id="footer-left-title"> Why choose us?</span> We respect our
          clients perspective and never try to force any idea. We always leave
          the final decision with the client after explaining him all the
          aspects of a design solution. Satisfaction of the end user is always
          the utmost priority for us. We provide you lifetime support even after
          the delivery of the project.
        </p>
        <div class="icons">
          <a
            href="https://www.facebook.com/aishwaryakhinvasaraa"
            target="_blank"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/sa_interior_scapes/?hl=en"
            target="_blank"
          >
            <i class="fa fa-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UC-vTo8hZf5CXbzvVKjLIizg"
            target="_blank"
          >
            <i class="fa fa-youtube"></i>
          </a>
          <a href="https://twitter.com/sainteriorscape" target="_blank">
            <i class="fa fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/aishwarya-khinvasara-18051998a/"
            target="_blank"
          >
            <i class="fa fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div class="footer-center col-md-4 col-sm-6">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span>
              {" "}
              582, Shukrawar Peth, Rajgulab <br /> Apartment, Near Hirabaug
              Chowk <br />
              Pune, Maharashtra 411002
            </span>{" "}
          </p>
        </div>
        <div>
          <i class="fa fa-phone"></i>
          <p style={{ fontWeight: "normal" }}>
            Please fill this{" "}
            <a as="a"
              href="#contactFormTitle"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                const form = document.getElementById("contactFormTitle");
                if (form) {
                  form.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the form
                }
              }}>
              <b>form</b>
            </a>{" "}
            to get a callback
          </p>
        </div>
        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:sainteriorscapes@gmail.com" target="_blank">
              {" "}
              sainteriorscapes@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div class="footer-right col-md-4 col-sm-6">
        <h2>
          {" "}
          SA Interior Scapes
          <span>
            {" "}
            <img src={SALogo} style={{ width: "3rem", height: "3rem" }} />
          </span>
        </h2>

        <p class="name"> SA Interior Scapes &copy; 2022</p>
        {/* <p id="developer">
          Website Developed by:{" "}
          <a href="https://www.linkedin.com/in/hrishikesh-gadekar" target="_blank">
            {" "}
            Hrishikesh Gadekar{" "}
          </a>
        </p> */}
      </div>
    </div>
  );
}

export default Footer;
