import React, { useState } from "react";
import ContactForm from "../ContactForm/ContactForm";
import ProjectCard from "../ProjectCard/ProjectCard";
import CarouselImage from "./Carousel";
import CarouselImageMobile from "./CarouselMobile";
import Statistics from "../Statistics/Statistics";
import Testimonial from "../Testimonials/Testimonial";
import YouTubeCard from "../YouTubeThumbnail/YouTubeCard";
import balewadiImage from "../../assets/imgs/Balewadi.jpg";
import calistoImage from "../../assets/imgs/Calisto.jpg";
import keshavNagarImage from "../../assets/imgs/KeshavNagar.jpg";
import aliveSkyparkImage from "../../assets/imgs/AliveSkypark.jpg";
import parkGrandeurImage from "../../assets/imgs/ParkGrandeur.jpg";
import legacy502Image from "../../assets/imgs/Legacy502.jpg";
import legacy702Image from "../../assets/imgs/Legacy702.jpg";
import pImage from "../../assets/imgs/Balewadi.jpg";
import aishwaryaImage from "../../assets/imgs/AishwaryaN.jpeg";
import manishImage from "../../assets/imgs/ManishN.jpeg";
import mayuriImage from "../../assets/imgs/MayuriN.jpeg";
import TeamInformation from "../Team/TeamInformation";
import HorizontalScrollbar3 from "../HorizontalScrollbar/HorizontalScrollbar3";
import HorizontalScrollbar6 from "../HorizontalScrollbar/HorizontalScrollbar6";
import AwardHeader from "../Awards/AwardHeader";
import "./Home.css";

function Home() {
  const projects = {
    0: {
      srno: 0,
      image: balewadiImage,
      title: "4 BHK Luxury Flat Design",
      subTitle: "Park Grandeur, Balewadi High Street",
      pagetitle: "Flat Interior Designing For Shradha Raina at Balewadi",
      clientName: "Shradha Raina",
      siteLocation: "Park Grandeur, Balewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated at Balewadi high street, Pune on the 18th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1d0cMbVefOqnVPEYR0ycNs3H82rC8Bd35",
    },
    1: {
      srno: 1,
      image: aliveSkyparkImage,
      title: "3 BHK Flat Design",
      subTitle: "Alive Skypark, Baner",
      pagetitle: "Flat Interior Designing For Mrs. Diksha and family at Baner",
      clientName: "Ritu Sharma",
      siteLocation: "Alive Skypark, Baner",
      siteType: "Residential House",
      siteSummary:
        "Designed and executed by sainteriorscapes the 1,100 sq ft, 3BHK alive sky park is located in suburban Pune. A warm and cosy 3BHK duplex for a young couple and their parents. As you enter the main home, the long living-dining area is broken into two halves with a ‘elliptical ceiling-to-floor curtain, creating a visual surprise to the eye. This brings newness to the otherwise usual rectangular structures of the room. The distinct division between the two parts in finishes, surface patterns and levels of both the walls and ceiling is enhanced. Each space is a canvas for bold usage of colors and patterns, which is evidently visible in areas like the kitchen or dining, paired with subtle soothing colors. Geometric work, or patterned fabrics and/or wallpapers, subsequently set a flow of many spaces in the house.",
      googleDriveLink: "1zl5CfFc3LZpaJq_T_0dbpkfXt-BEzGWH",
    },
    2: {
      srno: 2,
      image: keshavNagarImage,
      title: "3 BHK Luxury House Design",
      subTitle: "Keshav Nagar, Pune",
      pagetitle:
        "Flat Interior Designing For Amandeep & Ramanpreet at Keshav Nagar",
      clientName: "Amandeep Singh",
      siteLocation: "Florida Watercolour, Keshav Nagar",
      siteType: "Residential House",
      siteSummary:
        "Soni’s, an elegant modern classic 1500 sq. Ft. Flat in Pune, flaunts all the grace of the alluring bloom. The interiors were crafted in alignment with the client’s request for a well-organized space, calm and minimal interiors with a soothing color palette. The space had to be graceful and inspiring, matching the personality of family members.The dining room is an eye-catcher of the living room, where the colors of the dining table and the counter complement each other perfectly. In the dining room, a grey Italian marble top is paired with bold chairs. The beautiful pendant lights adorn the wooden ceiling, which is perfectly demarcating with the profile light. Parents' bedroom features shades of clay tones with classic window seating detailed with mouldings and a quirky capsule mirror against a subtle muted panelling. The balcony gives a complete break of fresh tropical painting with a vertical green arch.",
      googleDriveLink: "1zl5CfFc3LZpaJq_T_0dbpkfXt-BEzGWH",
    },
    3: {
      srno: 3,
      image: calistoImage,
      title: "House Design",
      subTitle: "Sukhwani Callisto, Wakad",
      pagetitle: "Flat Interior Designing For Ritu Sharma at Wakad",
      clientName: "Ritu Sharma",
      siteLocation: "Sukhwani Callisto, Wakad",
      siteType: "Residential House",
      siteSummary:
        "The home is inhabited by Jayesh and Ritu, accompanied by their son aged 14.  Built over 1,200 sq ft, this flat had to be designed to please and soothe every eye. So, we created a with the help of a modern classic theme. For the living room, we took classic moulding to create a TV wall with linear grooves at the tv ledge. In, for the dining space, we used bottle green chairs with round dining table for three with a marble top dining table with the fluted details below gave a modern and luxe feeling. The moulded square walls added an interesting contrast to the softness. We kept the bedroom theme modern and contemporary with maximum utilization of space.",
      googleDriveLink: "1zl5CfFc3LZpaJq_T_0dbpkfXt-BEzGWH",
    },
    4: {
      srno: 4,
      image: legacy702Image,
      title: "2 BHK Flat Design",
      subTitle: "702, Legacy Bliss, Hinjewadi",
      pagetitle: "Flat Interior Designing For Shradha Raina At Balewadi",
      clientName: "Shradha Raina",
      siteLocation: "702, Legacy Bliss, Hinjewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated in Hinjewadi, Pune on the 7th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1zl5CfFc3LZpaJq_T_0dbpkfXt-BEzGWH",
    },
    5: {
      srno: 5,
      image: legacy502Image,
      title: "2 BHK Flat Design",
      subTitle: "502, Legacy Bliss, Hinjewadi",
      pagetitle: "Flat Interior Designing For Shradha Raina At Balewadi",
      clientName: "Shradha Raina",
      siteLocation: "502, Legacy Bliss, Hinjewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated in Hinjewadi, Pune on the 5th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1zl5CfFc3LZpaJq_T_0dbpkfXt-BEzGWH",
    },
  };

  const team = {
    0: {
      image: aishwaryaImage,
      name: "Aishwarya Khinvasara",
      role: "Founder and Principal Designer",
      details: "",
    },

    2: {
      image: manishImage,
      name: "Manish Khinvasara",
      role: "Founder and Director, Constroreq.com",
      details: "",
    },
    3: {
      image: mayuriImage,
      name: "Mayuri Deshpande",
      role: "Interior Designer",
      details: "",
    },
  };

  const ongoingProjects = {
    0: {
      title: "2 BHK Flat Design",
      subTitle: "502, Legacy Bliss, Hinjewadi",
    },
    1: {
      title: "2 BHK Flat Design",
      subTitle: "502, Legacy Bliss, Hinjewadi",
    },
    2: {
      title: "2 BHK Flat Design",
      subTitle: "502, Legacy Bliss, Hinjewadi",
    },
  };

  const projectCards = Object.values(projects);
  const ongoingProjectCards = Object.values(ongoingProjects);
  const teamDetails = Object.values(team);

  return (
    <>
      <div id="coverImageDiv">
        <div id="carouselDesktop">
          <CarouselImage />
        </div>
        <div id="carouselMobile">
          <CarouselImageMobile />
        </div>
      </div>

      <div className="container">
        <AwardHeader />
      </div>

      <div className="container mb-3">
        <div className="row">
          <div className="col-md-3">
            <div id="aboutUs">
              <h3>About Us</h3>
              <p>
                SA-Interior-Scapes, a Pune-based Interior Design firm founded by
                <span id="aishwarya">
                  {" "}
                  <a
                    href="https://instagram.com/id.aishwarya_khinvasara?igshid=Zjc2ZTc4Nzk="
                    target="_blank"
                  >
                    Aishwarya Khinvasara
                  </a>
                </span>{" "}
                in 2018 , offers a fresh and modern approach to interior design.
                With a passion for creating beautiful spaces, the studio
                combines over 5 years of experience with a contemporary vision
                and a commitment to cutting-edge design solutions. Specializing
                in both residential and commercial projects, SA-Interior-Scapes
                has completed over 30+ projects across India, each one
                showcasing the firm's innovative and experimental approach. From
                each space's vibrant and welcoming atmosphere, it's clear that
                Aishwarya and her team are dedicated to departing from
                conventional notions of luxury and delivering premium design
                solutions that reflect the self-expression and comfort of their
                clients.
              </p>
            </div>
          </div>
          <div className="col-md-9">
            <ContactForm />
          </div>
        </div>
      </div>

      <div className="backgroundColorDiv">
        <div className="container mobile-card-container projectCards">
          <h3>Featured Projects</h3>
          <div className="row  flex-nowrap flex-sm-wrap">
            {projectCards.map((item) => {
              return (
                <>
                  <div className="col-md-4 ">
                    <ProjectCard cardDetails={item} />
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <HorizontalScrollbar6 />
      </div>

      <div className=" container testimonialDiv">
        <h3>Testimonials</h3>
        <Testimonial />
      </div>

      <div className="backgroundColorDiv">
        <div className="container mobile-card-container teamCard mt-2">
          <h3>Team</h3>
          <div className="row  flex-nowrap flex-sm-wrap">
            {teamDetails.map((item) => {
              return (
                <>
                  <div className="col-md-4">
                    <TeamInformation teamDetails={item} />
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <HorizontalScrollbar3 />
      </div>

      <div className="container mb-2" id="statsDiv">
        <h3>Statistics</h3>
        <Statistics />
      </div>

      <div className="backgroundColorDiv">
        <div className="mt-1 container mobile-card-container youTubeDiv">
          <h3>Social Media</h3>
          <YouTubeCard />
          {/* <InstagramEmbedTab /> */}
        </div>
        <HorizontalScrollbar3 />
      </div>

      <div className="websiteCounter"></div>
    </>
  );
}

export default Home;
