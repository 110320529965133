import React, { useEffect, useState } from "react";
import "./Statistics.css";

function Statistics() {
  const [currentInstagramFollowers, setCurrentInstagramFollowers] =
    useState("125k+");

  useEffect(() => {
    getInstagramFollowersCount();
  }, []);

  const getInstagramFollowersCount = async () => {
    const accessToken =
      "IGAANbYWPDny5BZAFBLc2ZAiVjBrR21LSmw5NXNZAZA0tSQ0JtbWpBd1lKVWhlTWhFNk1BYzRmSHcwWkFINzh1RHVsUXEtSlY2X2VFU0N4aXhJRjdzMmpSX1pXckpYcUw0SjFyWlQzU0V2TjFUNXVERjB0NGlLUm9aOTg2SHdlWENWNAZDZD";
    const apiUrl = `https://graph.instagram.com/me?fields=id,username&access_token=${accessToken}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.followers_count !== undefined) {
        setCurrentInstagramFollowers(data.followers_count); // Ensure this setter is properly defined.
      } else {
        console.warn("Followers count field not found in response.");
      }
    } catch (error) {
      console.error("Error fetching Instagram followers:", error);
    }
  };

  return (
    <div className="stats">
      <div className=" statBox">
        <i className="fas fa-project-diagram statIcon"></i>
        <h2>50+</h2>
        <p>Completed Projects</p>
      </div>
      <div className=" statBox">
        <i className="fab fa-instagram statIcon"></i>
        <h2>{currentInstagramFollowers}</h2>
        <p>Instagram Followers</p>
      </div>
      <div className=" statBox">
        <i className="fas fa-star statIcon"></i>
        <h2>4.9</h2>
        <p>Avg. Google Rating</p>
      </div>
      <div className=" statBox">
        <i className="fas fa-users statIcon"></i>
        <h2>75+</h2>
        <p>In-house Team</p>
      </div>
    </div>
  );
}

export default Statistics;
