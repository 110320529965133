import React from "react";
import "./AwardHeader.css";

function AwardHeader() {
  return (
    <>
      <div className="awards-box">
        <h1 className="awards-header">
          <span className="highlighted-text">Award-winning</span> design firm,
          <br className="desktop-line-break" />
          shaping spaces and redefining living with excellence since 2017
        </h1>
      </div>
    </>
  );
}

export default AwardHeader;
