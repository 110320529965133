import React, { useEffect, useContext } from "react";
import GoogleDriveImages from "./GoogleDriveImages";
import ProjectPageHeader from "./ProjectPageHeader";
import "./ProjectView.css";

function ProjectView({ projectDetails }) {
  useEffect(() => {
    window.scroll(0, 0);
  });
  const {
    title,
    subTitle,
    pagetitle,
    clientName,
    siteLocation,
    siteType,
    siteSummary,
    googleDriveLink,
  } = projectDetails;
  return (
    <>
      <div className="container projectHeader">
        <ProjectPageHeader />
      </div>

      <div className="container projectTitle">{pagetitle}</div>

      <div className="container projectSummary">
        <div className="row projectDetails">
          <div className="col-md-4 tabs">
            <span className="initials">Client:&nbsp;</span> {clientName}
          </div>
          <div className="col-md-4 tabs">
            <span className="initials">Location:&nbsp;</span> {siteLocation}
          </div>
          <div className="col-md-4 tabs">
            <span className="initials">Property Type:&nbsp;</span> {siteType}
          </div>
        </div>
        <p className="projectSummaryDesc">{siteSummary}</p>

        <div
          className="container sitePhotos"
          style={{
            alignItems: "center",
            verticalAlign: "center",
          }}
        >
          <div
            className="row"
            style={{
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <GoogleDriveImages googleDriveId={googleDriveLink} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectView;
