import Carousel from "react-bootstrap/Carousel";
import coverImage1 from "../../assets/imgs/cover2.jpg";
import coverImage2 from "../../assets/imgs/7.png";
import coverImage3 from "../../assets/imgs/1.png";
import coverImage4 from "../../assets/imgs/2.png";
import coverImage5 from "../../assets/imgs/5.png";
import "./Carousel.css";

const slides = [
  {
    src: coverImage2,
    alt: "First slide",
    brightness: "60%",
    label: "Customized designs and execution",
    text: "We prioritize your preferences to enrich the experience",
  },
  {
    src: coverImage5,
    alt: "Second slide",
    brightness: "70%",
    label: "Dream homes begin with dream designs",
    text: "We are here to make your dreams come to reality",
  },
  {
    src: coverImage3,
    alt: "Third slide",
    brightness: "70%",
    label: "Interiors that fit your budget",
    text: "Experience unmatched quality & timely delivery",
  },
  {
    src: coverImage4,
    alt: "Fourth slide",
    brightness: "70%",
    label: "Customized designs and execution",
    text: "We prioritize your preferences to enrich the experience",
  },
  {
    src: coverImage1,
    alt: "Fifth slide",
    brightness: "70%",
    label: "Dream homes begin with dream designs",
    text: "We are here to make your dreams come to reality",
  },
];

function CarouselImage() {
  return (
    <Carousel fade style={{ marginBottom: "1rem" }}>
      {slides.map((slide, index) => (
        <Carousel.Item
          key={index}
          interval={5000}
          className={
            index % 2 === 0 ? "carousel-item-zoom-in" : "carousel-item-zoom-out"
          }
        >
          <img
            className="d-block w-100 animated-image"
            style={{
              height: "65vh",
              objectFit: "cover",
              filter: `brightness(${slide.brightness})`,
            }}
            src={slide.src}
            alt={slide.alt}
          />
          <Carousel.Caption>
            <h4 className="slideLabel">{slide.label}</h4>
            <p className="slideSubLabel">{slide.text}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselImage;
