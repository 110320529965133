import Carousel from "react-bootstrap/Carousel";
import coverImage1 from "../../assets/imgs/cover2.jpg";
import coverImage2 from "../../assets/imgs/2M.png";
import coverImage3 from "../../assets/imgs/3M.png";
import coverImage4 from "../../assets/imgs/4M.png";
import coverImage5 from "../../assets/imgs/5M.png";
import coverImage6 from "../../assets/imgs/6M.png";
import "./Carousel.css";

function CarouselImageMobile() {
  return (
    <Carousel fade style={{ marginBottom: "1rem" }}>
      <Carousel.Item interval={5000} className="carousel-item-zoom-in">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(60%)",
          }}
          src={coverImage1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Customized designs and execution</h4>
          <p className="slideSubLabel">We prioritize your preferences to enrich the experience</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000} className="carousel-item-zoom-out">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Dream homes begin with dream designs</h4>
          <p className="slideSubLabel">We are here to make your dreams come to reality</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000} className="carousel-item-zoom-in">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Interiors that fit your budget</h4>
          <p className="slideSubLabel">Experience unmatched quality & timely delivery</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000} className="carousel-item-zoom-in">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage4}
          alt="First slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Customized designs and execution</h4>
          <p className="slideSubLabel">We prioritize your preferences to enrich the experience</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000} className="carousel-item-zoom-out">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage5}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Dream homes begin with dream designs</h4>
          <p className="slideSubLabel">We are here to make your dreams come to reality</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000} className="carousel-item-zoom-in">
        <img
          className="d-block w-100 animated-image"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage6}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Dream homes begin with dream designs</h4>
          <p className="slideSubLabel">We are here to make your dreams come to reality</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselImageMobile;
