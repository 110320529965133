import React from "react";
import GDImageViewer from "../../GDImageViewer/src/GDImageViewer";
import "./GoogleDriveImages.css";

function GoogleDriveImages({ googleDriveId }) {
  console.log(googleDriveId);
  console.log(typeof googleDriveId);
  const imagesData = {
    gkey: "AIzaSyAG0qs0QS4tXCkd5uYncmPK45956rLYVN0",
    dirId: googleDriveId,
    name: "name1",
    options: [
      {
        // react style object
        // https://reactjs.org/docs/dom-elements.html#style

        style: {
          display: "flex",
          width: "20px",
          height: "20px",
        },

        // behavior when image is clicked
        // if on click is empty (no modal or newWindow)
        // current tab will show full image
        // if modal true, image opens as overlay
        // on current tab
        // if new window is true, new tab is launched
        // with image url

        onClick: {
          modal: true,
          newWindow: false,
        },

        //Use name of <FILE_NAME>.<EXTENSION> (image.png) to
        // specify which images not to render

        exclude: {
          "1.jpg": true,
        },

        //Use name of <FILE_NAME>.<EXTENSION> (image.png) to
        //attach className attribute to a specific image

        attachClass: {
          "2.jpg": "test",
        },

        //Use name of <FILE_NAME>.<EXTENSION> (image.png) to
        //attach id attribute to a specific image

        attachId: {
          "2.jpg": "test2",
        },

        // if set true, hover over opacity effect
        // will be set

        hover: true,
      },
    ],
  };
  console.log(imagesData);

  return (
    <div className="container">
      <h6>Site Photos</h6>
      <div className="row">
        <GDImageViewer data={imagesData} />
      </div>
    </div>
  );
}

export default GoogleDriveImages;
