import React from "react";
import { Link } from "react-router-dom";
import ProjectHeaderBackground from "../../../src/assets/imgs/project_header_background.jpg";

function ProjectPageHeader() {
  return (
    <>
      <img
        src={ProjectHeaderBackground}
        style={{
          width: "100%",
          height: "12vh",
          objectFit: "cover",
          filter: "brightness(30%)",
        }}
      />
      <div
        className="centered"
        style={{
          letterSpacing: "0.25em",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        Project
      </div>
      <div className="bottom-left">
        <Link
          to="/"
          style={{ textDecoration: "underline", color: "whitesmoke" }}
        >
          {" "}
          <span>Home</span>{" "}
        </Link>
        &nbsp;/&nbsp; Project
      </div>
    </>
  );
}

export default ProjectPageHeader;
